import React from "react"

const MerchantDirectory = ({ pageContext: {text} }) => {

  return (
    <div className="legal container my-5 py-5">
		<h1>BITTORRENT TOKEN</h1>
		<h2>MERCHANT DIRECTORY TERMS AND CONDITIONS</h2>
		<p>Last Updated: November 15, 2021</p>
		<hr style={{ margin: `2rem 0` }} />

		<p>&emsp;&emsp;These Merchant Directory Terms and Conditions (&ldquo;<strong>Agreement Terms</strong>&rdquo;) govern your participation (&ldquo;<strong>Merchant</strong>&rdquo; or &ldquo;<strong>you</strong>&rdquo;) in the BitTorrent Token Merchant Directory (&ldquo;<strong>BTT Directory</strong>&rdquo;), provided by Rainberry, Inc., a California corporation (&ldquo;<strong>Company</strong>&rdquo;). Merchant and Company collectively referred to herein, as the &ldquo;<strong>Parties</strong>.&rdquo;
		</p>
		<p>&emsp;&emsp;<strong>Registration and Agreement to Terms</strong>. To participate in the BTT Directory, you must register with Company by submitting an approved BitTorrent Token Directory Merchant Listing Form. By registering with the Directory, you agree to be bound by these Agreement Terms. If you do not agree to these Agreement Terms, do not register with the BTT Directory. If you are registering with the BTT Directory on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that entity to these Terms. In that case, &ldquo;Merchant&rdquo; will refer to that entity. In consideration of Company&rsquo;s display and inclusion of Merchant&rsquo;s specified offerings in Company&rsquo;s BTT Directory, Merchant agrees to the following terms:
		</p>
		<p>&emsp;&emsp;<strong>Scope</strong>. For the duration of the Agreement, Company shall display Merchant&rsquo;s name, the title of Merchant&rsquo;s applicable product or service (the &ldquo;<strong>Listing</strong>&rdquo;), and the Listing&rsquo;s description in Company&rsquo;s BTT Directory. Per Merchant Listing Form, Merchant shall provide Company with Merchant&rsquo;s full legal name, title of the Merchant&rsquo;s Listing, description of the Merchant&rsquo;s Listing, and URL through which third parties may independently access Merchant&rsquo;s online catalogue of offerings that may be purchased with BitTorrent Token (<strong>&ldquo;BTT&rdquo;</strong>).
		</p>
		<p>&emsp;&emsp;<strong>Merchant Warranties</strong>. As of the Effective Date, Merchant warrants that Merchant has successfully enabled and supports purchase of the Listing with BTT; by actively leveraging CoinPayments or a similar intermediary platform to facilitate purchase of the Listing with BTT. Merchant shall continually enable and support purchase of the Listing with BTT for the duration of the Agreement. In the event Merchant ceases to successfully enable, support, or facilitate purchase of the Listing with BTT, Merchant shall provide written notice to Company no less than five (5) business days prior to cessation of purchase support (<a href="mailto:legal@bittorrent.com">legal@bittorrent.com</a>).
		</p>
		<p>&emsp;&emsp;<strong>Prohibited Activities and Prohibited Territories.</strong> Merchant is expressly prohibited from the submission of Listings, the sale of which, is unlawful pursuant to the laws of the United States, European Union, or jurisdiction in which the Merchant or third-party consumer reside. Merchant represents and warrants that any Listing submitted for inclusion in the BTT Directory shall meet the requirements of the proceeding provision. Merchant warrants that it is not located in, and shall not provide goods or services to, a third-party consumer located in a territory which is subject to OFAC restrictions.
		</p>
		<p>&emsp;&emsp;<strong>Term.</strong> The Term (as defined below) of this Agreement will commence upon the Effective Date and will, unless earlier terminated as set forth herein, continue for a period of twelve (12) months. At the end of the Initial Term, this Agreement will automatically renew for additional, successive twelve (12) month periods (each, a &ldquo;Renewal Term&rdquo; and the Initial Term and all Renewal Terms collectively, the &ldquo;Term&rdquo;), unless one party notifies the other in writing (email shall be sufficient) of its intention not to renew at least thirty (30) days prior to the end of the then-current Term.
		</p>
		<p>&emsp;&emsp;<strong>Termination.</strong> Company or Merchant may terminate this Agreement immediately in the event that the other Party fails to cure a material breach of this Agreement and that breach is not remedied within five (5) days of its receipt of written notice. Pursuant to written notice, Company may terminate this Agreement immediately and remove Merchant from the Directory, for any reason, or in the event Merchant (collectively with Merchant&rsquo;s parent, subsidiaries or affiliates) engages in conduct that reasonably endangers Company&rsquo;s brand, valuation or goodwill; or for reason pursuant to Company&rsquo;s discretion and concurrent notice.
		</p>
		<p>&emsp;&emsp;<strong>Relationship.</strong> Merchant acknowledges and expressly warrants that Company shall not sell Merchant&rsquo;s products and or services. Merchant is an independent contractor, and nothing contained herein shall be deemed to create a relationship of employment, partnership, principal and agent, or joint venture between Company and Merchant. Merchant shall have no power or authority to make any commitments, undertakings or agreements in the name of and/or on behalf of Company, whether verbal or written, and will not hold itself out as having any such power or authority.
		</p>
		<p>&emsp;&emsp;<strong>Rights Granted</strong>. Merchant grants Company a limited, revocable, worldwide, license free of charge, to display Merchant&rsquo;s logo and or trademark related to Merchant&rsquo;s Listing for the duration of the Agreement. Company may include Merchants logo and or trademark in marketing materials, including social media for the duration of the Agreement.
		</p>
		<p>&emsp;&emsp;<strong>Proprietary Rights.</strong> Company and/or its affiliates own and shall retain all right, title, and interest in and to the Company Services (and any part thereof, including the BTT Directory), and any content and data generated through use of the Company Services, including all intellectual property rights therein. This Agreement does not grant Merchant any rights to Company&rsquo;s or its affiliates&#39; intellectual property (including any rights in respect to the BTT Directory and any of Company&#39;s Confidential Information), and any goodwill, rights, and benefits that arise from their use will inure solely to Company. For the avoidance of doubt, an end user shall retain all right, title and interest in such end user&#39;s personal data; provided that Company is entitled to retain and use such information solely for the purpose of providing services to its clients.
		</p>
		<p>&emsp;&emsp;<strong>Company Warranties.</strong> THE SERVICES, THE BTT DIRECTORY, AND ANY OTHER MATERIALS PROVIDED BY COMPANY HEREUNDER ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE,&rdquo; AND COMPANY MAKES NO WARRANTIES WITH RESPECT TO THE SAME OR OTHERWISE IN CONNECTION WITH THIS AGREEMENT AND HEREBY DISCLAIMS ANY AND ALL EXPRESS, IMPLIED, OR STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. TO THE EXTENT THAT COMPANY MAY NOT AS A MATTER OF APPLICABLE LAW DISCLAIM ANY IMPLIED WARRANTY, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE THE MINIMUM PERMITTED UNDER SUCH LAW.
		</p>
		<p>&emsp;&emsp;<strong>Limitation of Liability.</strong> EXCEPT IN CONNECTION WITH A PARTY&rsquo;S WILLFUL MISCONDUCT, GROSS NEGLIGENCE, OR A PARTY&rsquo;S INDEMNIFICATION OBLIGATIONS: (I) IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY OR ANY OTHER PARTY FOR ANY INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, LOST REVENUES OR PROFITS) ARISING FROM OR RELATING TO THIS AGREEMENT, REGARDLESS OF WHETHER SUCH PARTY WAS ADVISED, HAD OTHER REASON TO KNOW, OR IN FACT KNEW OF THE POSSIBILITY THEREOF; AND (II) EACH PARTY&rsquo;S AGGREGATE LIABILITY FOR DIRECT DAMAGES UNDER THIS AGREEMENT WILL NOT EXCEED $5,00.00 (FIVE HUNDRED DOLLARS, USD).
		</p>
		<p>&emsp;&emsp;<strong>Indemnification.</strong> Each Party (the &ldquo;Indemnifying Party&rdquo;) will defend, indemnify, and hold harmless the other Party, its Affiliates, and their respective officers, directors, and agents (collectively, the &ldquo;Indemnified Parties&rdquo;) from any and all liabilities, costs, and expenses (including reasonable attorneys&rsquo; fees) incurred by such Indemnified Parties in connection with any third-party claim, action, or proceeding (each, a &ldquo;Claim&rdquo;) arising from the Indemnifying Party&rsquo;s fraud, willful misconduct and/or breach of its representations and warranties set forth in this Agreement provided, however, that the foregoing obligations will be subject to the Indemnified Parties: (i) promptly notifying the Indemnifying Party of the Claim; (ii) providing the Indemnifying Party, at the Indemnifying Party&rsquo;s expense, with reasonable cooperation in the defense of the Claim; and (iii) providing the Indemnifying Party with sole control over the defense and negotiations for a settlement or compromise of the Claim.
		</p>
		<p>&emsp;&emsp;<strong>Governing Law</strong>. This Agreement or any claim, cause of action or dispute (&ldquo;claim&rdquo;) arising out of or related to this Agreement shall be governed by the laws of the state of California regardless of Merchant&rsquo;s country of origin, and notwithstanding of any conflicts of law principles and the United Nations Convention for the International Sale of Goods. In the event of any dispute, controversy or claim arising out of or relating to this Agreement, or the breach, termination or validity of it, this Agreement shall be settled by binding arbitration in the County of San Francisco, California, accordance with the rules of the American Arbitration Association applying the substantive law of the state of California, without regard to any conflict of law provisions. The arbitration will be governed by the United States Arbitration Act 9 U.S.C. Sec. 1 et seq., and judgment upon the award rendered by the arbitrator may be entered by any court with jurisdiction.
		</p>
		<p>&emsp;&emsp;<strong>Merger and Severability</strong>. This Agreement, and each exhibit referenced herein, constitute the entire Agreement among the parties with respect to the subject matter hereof and supersedes any prior negotiations, understandings and agreements. Each provision of this Agreement shall be severable from every other provision of this Agreement for the purpose of determining the legal enforceability of any specific provision. If any provision of this Agreement shall be held or made invalid or unenforceable by a court decision, statute, rule or otherwise, the remaining provisions of this Agreement shall not be affected thereby and shall continue in full force and effect.
		</p>
		<p>&emsp;&emsp;<strong>Miscellaneous.</strong> No waiver will be implied from conduct or failure to enforce or exercise rights under this Agreement, nor will any waiver be effective unless in a writing signed by a duly authorized representative on behalf of the party claimed to have waived. This Agreement shall bind and inure to the benefit of the respective successors and assigns of each of the parties. Merchant may not assign this Agreement or any rights or duties hereunder without the prior written consent of Company (such consent to not be unreasonably withheld); The section headings are for convenience only and shall not affect the interpretation or construction of this Agreement.
		</p>

	</div>

  )
}

export default MerchantDirectory